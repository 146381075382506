/**
 *  invitation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de visualización del QR
 */

import React, { Component } from "react";
import moment from "moment-timezone";
import Request from "../core/httpClient";
import LogoApp from "../img/logo.png";
import imgQr from "../img/qr-code.png";

const request = new Request();

class Invitation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: "",
			data: "",
			nombre_invitado: "",
			expiracion: moment(),
			valid: true,
		};
	};

	componentDidMount() {

		if (this.props.match.params) {

			this.load(this.props.match.params);
		};
	};

	async load(params) {

		this.setState({ loading: true });

		const response = await request.post("/invitaciones/get/databytoken", { token: params.id });

		if (response.completar) {
			window.location = `/complete/${params.id}`;
			return;
		};

		if (response.error) {

			this.setState({
				error: response.error,
				message: response.message,
				loading: false,
				valid: false,
			});

			return;

		} else {

			if (response.number) {

				this.setState({
					telephone: response.number,
					id: response.id,
					vivienda: response.vivienda,
					inquilino: response.inquilino,
					expiracion: response.expiracion,
					horaPermitida: response.horaPermitida,
					entradas: response.entradas ? response.entradas : 2,
				});

				const data = {
					id: response.id,
					number: response.number,
					vivienda: this.state.vivienda,
					inquilino: this.state.inquilino,
					expiracion: response.expiracion,
					idToken: response.idToken,
					invitacion: response.invitacion,
				};

				const dataValidate = { idInvitacion: response.invitacion };

				const responseValid = await request.post(
					"/invitaciones/validar",
					dataValidate
				);

				if (responseValid && !responseValid.error) {

					if (responseValid.status === 1) {

						let responseInvitation = "";

						if (response.invitacion) {

							responseInvitation = await request.post(
								"/invitaciones/get/invitation",
								data
							);

						} else {
							responseInvitation = await request.post(
								"/invitaciones/set/invitation",
								data
							);
						};

						if (responseInvitation.qr) {

							await this.setState({
								qr: `data:image/jpeg;base64,${responseInvitation.qr}`,
								nombre_invitado: responseInvitation.nombre_invitado,
								expiracion: responseInvitation.expiracion,
								fraccionamiento: responseInvitation.fraccionamiento,
								sub_fraccionamiento: responseInvitation.sub_fraccionamiento,
								showQR: true,
								valid: true,
								loading: false,
								entrada1: responseInvitation.entrada1,
								salida1: responseInvitation.salida1,
								entrada2: responseInvitation.entrada2,
								salida2: responseInvitation.salida2,
								horaPermitida: responseInvitation.horaPermitida,
								entradasUsadas:
									(responseInvitation.entrada1 === null ? 0 : 1) *
									(responseInvitation.salida1 === null ? 0 : 1) +
									(responseInvitation.entrada2 === null ? 0 : 1) *
									(responseInvitation.salida2 === null ? 0 : 1),
							});

						} else {
							this.setState({
								error: responseInvitation.error,
								message: responseInvitation.message,
								loading: false,
								valid: false,
							});
						};

					} else if (responseValid.status === 2) {

						this.setState({
							error: true,
							message: "La invitación ha expirado",
							loading: false,
							valid: false,
						});

					} else if (responseValid.status === 3) {

						this.setState({
							error: true,
							message: "La invitación ya ha sido utilizada",
							loading: false,
							valid: false,
						});

					} else {

						this.setState({
							error: true,
							message: "La invitación ha sido cancelada",
							loading: false,
							valid: false,
						});
					};

				} else {

					alert("ERROR DE CONEXIÓN");

					this.setState({
						error: responseValid.error,
						message: responseValid.message,
						loading: false,
						valid: false,
					});

				};
			};
		};
	};

	download() {

		var link = document.createElement("a");
		var img = document.getElementById("imgQr").src;

		link.href = img;
		link.download = `AppMospheraInvitation${moment().format("YYYYMMDDHHmmss")}.jpg`;

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	render() {
		return (
			<div className="invitation">
				<div className="justify-center">
					<div className="container column">
						<div className="white-space-16"></div>
						<div className="justify-center align-center">
							{this.state.loading ? (
								<div className="column">
									<div className="justify-center">
										<i className="fas fa-spinner fa-2x fa-spin"></i>
									</div>
									<div className="white-space-16"></div>
									<p className="text-center font-regular weight-medium loading-text">
										Cargando invitación
									</p>
								</div>
							) : (
								this.renderQr()
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderQr() {
		return (
			<React.Fragment>
				<div className="justify-center column">
					{new Date(moment().tz("America/Cancun").format("MM-DD-YYYY 00:00")) > new Date(moment(this.state.expiracion).tz("America/Cancun").format("MM-DD-YYYY 00:00"))
						? this.expirado()
						: new Date(moment(this.state.horaPermitida).tz("America/Cancun").format("MM-DD-YYYY HH:mm")) > new Date(moment().tz("America/Cancun").format("MM-DD-YYYY HH:mm"))
							? this.visibleHasta()
							: this.state.entradasUsadas < this.state.entradas
								? this.qrcode()
								: this.expirado()
					}
					<div className="row justify-center align-center">
						<div className="column justify-center">
							<div className="line-black"></div>
						</div>
						<div className="column align-center">
							<img
								className="image-responsive"
								width="50px"
								height="50px"
								src={LogoApp}
								alt="Logo AppMosphera"
								title="Logo AppMosphera"
							/>
						</div>
						<div className="column justify-center">
							<div className="line-black"></div>
						</div>
					</div>
					<div className="white-space-16"></div>
				</div>
			</React.Fragment>
		);
	};

	expirado() {
		return (
			<React.Fragment>
				<div className="justify-center">
					<p className="text-center">
						Fecha:{" "}
						<b>
							{moment(this.state.horaPermitida)
								.add(31, 'minutes')
								.tz("America/Cancun")
								.format("DD-MM-YYYY HH:mm")}
						</b>
					</p>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center">
					<div className="justify-center qr">
						<img
							id="imgQr"
							src={imgQr}
							alt="AppMosphera QR"
							className="qrdefault"
							title="AppMosphera QR"
							width="250px"
							height="250px"
						/>
					</div>
				</div>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<p className="text-center font-regular">
						<b className="font-regular">{this.state.message || 'Su invitación ya expiró.'}</b>
					</p>
				</div>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<p className="text-center">
						<b className="font-mini"> Gracias por utilizar AppMosphera.</b>
					</p>
				</div>
				<div className="white-space-24"></div>
			</React.Fragment>
		);
	};

	visibleHasta() {
		return (
			<React.Fragment>
				<div className="justify-center">
					<p className="text-center">
						<b >{this.state.fraccionamiento}</b>
					</p>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center">
					<p className="text-center">
						Fecha:{" "}
						<b>
							{moment(this.state.horaPermitida)
								.add(31, 'minutes')
								.tz("America/Cancun")
								.format("DD-MM-YYYY HH:mm")}
						</b>
					</p>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center">
					<p className="text-center">
						<b className="font-large">{this.state.nombre_invitado}</b>
					</p>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center">
					<div className="justify-center qr">
						<img
							id="imgQr"
							src={imgQr}
							className="qrdefault"
							alt="AppMosphera QR"
							title="AppMosphera QR"
							width="250px"
							height="250px"
						/>
					</div>
				</div>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<div className=" column">
						<p className="text-center font-regular">El código de acceso estará disponible 30 minutos antes de la llegada.</p>
					</div>
				</div>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<p className="text-center">
						<b className="font-mini"> Gracias por utilizar AppMosphera.</b>
					</p>
				</div>
				<div className="white-space-24"></div>
			</React.Fragment>
		);
	};

	qrcode() {
		return (
			<React.Fragment>
				<div className="justify-center">
					<p className="text-center">
						<b >{this.state.fraccionamiento}</b>
					</p>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center">
					<p className="text-center">
						Fecha:{" "}
						<b>
							{moment(this.state.horaPermitida)
								.add(31, 'minutes')
								.tz("America/Cancun")
								.format("DD-MM-YYYY HH:mm")}
						</b>
					</p>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center">
					<p className="text-center">
						<b className="font-large">{this.state.nombre_invitado}</b>
					</p>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center qr-container">
					<div className="justify-center qr">
						<img
							id="imgQr"
							src={this.state.qr}
							alt="AppMosphera QR"
							title="AppMosphera QR"
							width="250px"
							height="250px"
						/>
					</div>
				</div>
				<div className="white-space-8"></div>
				<div className="justify-center">

					<p className="text-center font-regular uppercase">
						<b className="font-regular">
							{this.state.entrada1 === null
								? "Entrada"
								: this.state.salida1 === null
									? "Salida"
									: this.state.entrada2 === null
										? "Entrada"
										: "Salida"}
						</b>
					</p>

				</div>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<p className="text-center font-regular">
						Presenta este código QR junto a tu identificación en la caseta de
						seguridad.
					</p>
				</div>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<div className="table-content column">
						<p className="text-center uppercase table-title">Respete las normas de convivencia</p>
						<div className="white-space-4"></div>
						<p className="uppercase table-title">1.- Velocidad máxima: 30km/h</p>
						<div className="white-space-4"></div>
						<p className="uppercase table-title">2.- Ceder el paso a las personas</p>
						<div className="white-space-4"></div>
						<p className="uppercase table-title">3.- No tirar la basura en la calle</p>
						<div className="white-space-4"></div>
						<p className="uppercase table-title">4.- No usar áreas comunes sin autorización</p>
						<div className="white-space-4"></div>
						<p className="uppercase table-title">5.- No deje a los niños solos</p>
					</div>
				</div>
				<div className="white-space-8"></div>
				<div className="align-center column">
					<button className="btn" onClick={this.download.bind(this)}>
						Descargar <i className="fas fa-download"></i>
					</button>
				</div>
			</React.Fragment>
		);
	};
};

export default Invitation;