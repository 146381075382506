/**
 *  invitation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página de registro de usuario para generar QR
 */

import React, { Component } from "react";
import moment from "moment-timezone";

import HeaderSimple from "../components/header/header-simple";
import Request from "../core/httpClient";

const request = new Request();

class Complete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countries: [],
			states: [],
			image: "",
			data: "",
			telephone: "",
			id: 0,
			created: true,
			expiracion: "",
			idToken: 0,
		};
	};

	componentDidMount() {

		if (this.props.match.params) {
			this.load(this.props.match.params);
		};
	};

	async load(params) {

		this.setState({ loading: true });

		const response = await request.post("/invitaciones/get/databytoken", { token: params.id });

		if (response.error) {
			this.setState({ error: response.error, message: response.message, loading: false });
		} else {

			if (response.created === 0) {

				if (response.number) {

					this.setState({
						telephone: response.number,
						id: response.id,
						vivienda: response.vivienda,
						inquilino: response.inquilino,
						created: false,
						expiracion: response.expiracion,
						idToken: response.idToken,
						loading: false,
						invitacion: response.invitacion,
						entrada1: response.entrada1,
						salida1: response.salida1,
						entrada2: response.entrada2,
						salida2: response.salida2,
						horaPermitida: response.horaPermitida,
						entradas: response.entradas ? response.entradas : 2,
						entradasUsadas: ((response.entrada1 === null ? 0 : 1) * (response.salida1 === null ? 0 : 1)) + ((response.entrada2 === null ? 0 : 1) * (response.salida2 === null ? 0 : 1))
					});

				} else {
					this.setState({ error: response.error, message: response.message, loading: false });
				};

			} else {
				this.setState({ message: "YA EXISTE EL USUARIO REGISTRADO EN APPMOSPHERA", loading: false });
			};
		};
	};

	render() {
		return (
			<div id="completeBody" className="column complete align-center justify-center">
				<HeaderSimple />
				<div className="justify-center align-center justify-center">
					{this.state.loading ? (
						<div className="container column">
							<div className="justify-center">
								<i className="fas fa-spinner fa-2x fa-spin"></i>
							</div>
						</div>
					)
						: this.renderForm()
					}
				</div>
			</div>
		);
	};

	renderForm() {
		return (
			<React.Fragment>
				<form className="container column align-center justify-center" onSubmit={this.handleSubmit.bind(this)}>
					<div className="white-space-16"></div>
					<div className="white-space-4"></div>
					<div className="column justify-center align-center">
						<p className="font-large ">¡Nos interesa tu seguridad!</p>
						<div className="white-space-8"></div>
					</div>
					<div className="white-space-16"></div>
					<div className="row align-center justify-center condition">
						<input
							type="checkbox"
							className="input-textbox"
							id="acept_policy"
							name="acept_policy"
							required
						></input>
						<p className="">
							&nbsp; He leido y acepto la &nbsp;
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://appmosphera.com/politicas.html"
							>
								Politica de privacidad
							</a>
						</p>
					</div>
					<div className="white-space-16" />
					<button
						className="btn btn-primary btn-small color-white"
						disabled={this.state.created}
						type="submit"
					>
						ACEPTAR
					</button>
					<div className="white-space-16" />
					<div className="column align-center justify-center">
						<p className="weight-medium">{this.state.message}</p>
					</div>
				</form>
			</React.Fragment>
		);
	};

	async handleSubmit(event) {

		event.preventDefault();

		this.setState({ loading: true, showQR: false });

		const form = event.target;

		const data = {
			id: this.state.id,
			number: this.state.telephone,
			name: form.name.value,
			vivienda: this.state.vivienda,
			inquilino: this.state.inquilino,
			expiracion: moment(this.state.expiracion).tz("America/Cancun").format("YYYY-MM-DD HH:mm:ss"),
			idToken: this.state.idToken,
			invitacion: this.state.invitacion,
		};

		const response = await request.post("/invitaciones/set/guestdata", data);

		if (response.qr) {

			this.setState({
				qr: `data:image/jpeg;base64,${response.qr}`,
				nombre_invitado: response.nombre_invitado,
				expiracion: response.expiracion,
				showQR: true,
				fraccionamiento: response.fraccionamiento,
				sub_fraccionamiento: response.sub_fraccionamiento,
			});

			window.location = "/invitation/" + this.props.match.params.id;
		};
	};
};

export default Complete;