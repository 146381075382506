/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Páginas Web
import Invitation from '../pages/invitation';
import Complete from '../pages/complete';
import Pedido from '../pages/pedido';

class Routes extends Component {
    render() {
        return (            
            <BrowserRouter>
                <div className="flex main">
                    <div className="column full">
                        <Switch>
                            <Route path="/" exact component = { () => { window.location = "https://appmosphera.app/" } } />
                            <Route path="/complete/:id" exact component = { Complete } />
                            <Route path="/invitation/:id" exact component= { Invitation } />
                            <Route path="/pedido/:id" exact component = { Pedido } />
                        </Switch>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}

export default Routes;